// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const supplierSlice = createSlice({
  name: "supplier",
  initialState: {},
  reducers: {
    getSupplier: (state, action) => {
      state["supplier"] = action.payload;
    },
    deletecustomer: (state, action) => {
      state["supplier"] = action.payload;
    },
  },
});

export const { getSupplier } = supplierSlice.actions;

export default supplierSlice.reducer;
