// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
export const businessDropdownSlice = createSlice({
  name: "businessesList",
  initialState: {},
  reducers: {
    getBusinessList: (state, action) => {
      state["businessList"] = action.payload;
    },
  },
});

export const { getBusinessList } = businessDropdownSlice.actions;

export default businessDropdownSlice.reducer;
