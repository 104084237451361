// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const CompanySlice = createSlice({
  name: "CompanySelected",
  initialState: {},
  reducers: {
    getSelectedCompany: (state, action) => {
      state["company"] = action.payload;
    },
  },
});

export const { getSelectedCompany } = CompanySlice.actions;
export default CompanySlice.reducer;
