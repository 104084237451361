// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const businessSlice = createSlice({
  name: "businesses",
  initialState: {},
  reducers: {
    getBusiness: (state, action) => {
      state["businesses"] = action.payload;
    },
    deleteBusiness: (state, action) => {
      state["businesses"] = action.payload;
    },
  },
});

export const { getBusiness } = businessSlice.actions;

export default businessSlice.reducer;
