// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state["userData"] = action.payload.data;
      state["token"] = action.payload.data.access;
      state["refreshToken"] = action.payload.data.refresh;
      localStorage.setItem("userData", JSON.stringify(action.payload.data));
      localStorage.setItem("token", action.payload.data.access);
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(action.payload.data.refresh)
      );
    },
    handleLogout: (state) => {
      state.userData = {};
      state["token"] = null;
      state["refreshToken"] = null;
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("company");
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
