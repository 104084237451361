// ** Reducers Imports
import auth from "./authentication";
import business from "./Business";
import businessDropdown from "./BusinessDropdown";
import customers from "./customers";
import dropdown from "./Dropdowns";
import layout from "./layout";
import navbar from "./navbar";
import company from "./selectedCompany";
import suppliers from "./suppliers";
const rootReducer = {
  auth,
  navbar,
  layout,
  dropdown,
  company,
  business,
  businessDropdown,
  customers,
  suppliers,
};

export default rootReducer;
