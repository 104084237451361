// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const customerslice = createSlice({
  name: "customers",
  initialState: {},
  reducers: {
    getcustomer: (state, action) => {
      state["customers"] = action.payload;
    },
    deletecustomer: (state, action) => {
      state["customcustomerseres"] = action.payload;
    },
  },
});

export const { getcustomer } = customerslice.actions;

export default customerslice.reducer;
