// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: {
    dropdown: [],
  },
  reducers: {
    getDropdowns: (state, action) => {
      state["dropdown"] = action.payload;
    },
  },
});

export const { getDropdowns } = dropdownSlice.actions;

export default dropdownSlice.reducer;
